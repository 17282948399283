body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 16px;
  color: #010101;
  --sidebar: 260px;
  --theme: #1c0d84;
  --theme-rgb: 28, 13, 132;
  height: 100%;
  overflow: hidden;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: none;
}
* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font: inherit;
  color: inherit;
  text-decoration: none;
  list-style: none;
  cursor: inherit;
}
button:not(:disabled),
a {
  cursor: pointer;
}
#root {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #fff;
  flex-grow: 0;
  min-width: 0;
  --gap: 2px;
  position: relative;
  padding: 0 20px;
  --max-width: 1000px;
  /*
  margin-top: 16px;
    height: calc(100vh - 16px);
  border-radius: 20px 0 0 0;
  */
  margin-left: calc(var(--sidebar) + var(--gap));
  width: calc(100% - var(--sidebar) - var(--gap));
  min-height: 100vh;
  box-shadow:
    0 0 0 1px #cfcfcf,
    0 1px 2px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
