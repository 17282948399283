.root {
  background-color: #e7e6eb;
  width: var(--sidebar);
  max-height: 100vh;
  overflow-y: auto;
  top: 0;
  height: 100%;
  min-height: 0;
  position: fixed;
  /*box-shadow: inset -1px 0 0 0 #cfcfcf;*/
  padding: 20px 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  border-radius: 0;
}
body {
  background-color: #e7e6eb;
}
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 25px;
  color: #1c1c1c;
  line-height: 28px;
  user-select: none;
  margin-bottom: 4px;
}
.header > svg {
  filter: drop-shadow(-5px -3px 15px rgba(var(--theme-rgb), 0.3))
    drop-shadow(4px 3px 8px rgba(var(--theme-rgb), 0.1));
  width: fit-content;
  height: 28px;
  color: var(--theme);
}
.switcher {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 42px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #c2c2c2;
  padding: 6px;
  cursor: pointer;
  user-select: none;
}
.switcher .current {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-size: 14px;
  line-height: 15px;
  font-weight: 450;
  color: #1d1d1d;
}
.switcher .current > picture {
  height: 100%;
  display: block;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  width: auto;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat;
}
.switcher > svg {
  width: 20px;
  margin-right: 4px;
  color: #4a4a4a;
}
.divider {
  height: 1px;
  width: 100%;
  flex-shrink: 0;
  background-color: #d4d4d4;
}
.search {
  height: 42px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px;
  padding-left: 10px;
  background-color: #0000000d;
  font-size: 13px;
  line-height: 15px;
  gap: 6px;
  font-weight: 450;
  color: #4a4a4a;
}
body .search {
  cursor: text;
}
.search > svg {
  width: 20px;
}
.nav {
  display: flex;
  flex-flow: column nowrap;
  gap: 3px;
}
.navHeader {
  height: 14px;
  font-size: 12px;
  font-weight: 500;
  color: #6a6a6a;
  margin-bottom: 2px;
  padding-left: 8px;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.navHeader > button {
  cursor: pointer;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  transition: all 0.2s ease-in-out;
}
.navHeader > button:hover {
  color: #000;
}
.navHeader > button > svg {
  width: 18px;
  flex-shrink: 0;
  height: 18px;
}
.nav .title {
  line-height: 13px;
}
.link {
  height: 38px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #1d1d1d;
  gap: 6px;
  transition:
    all 0.2s ease-in-out,
    transform 0ms linear;
  border-radius: 4px;
  transform-origin: center;
  user-select: none;
  background-size: 300% 100%;
  background-position: left center;
  background-repeat: no-repeat;
}
.link > svg {
  width: 20px;
  height: fit-content;
  color: #4a4a4a;
  transition: all 0.2s ease-in-out;
}
.link:not(.active):hover {
  background-color: #0000000d;
}
.link:not(.active):active {
  transform: scale(0.98);
}
.link.active {
  background-color: #00000021;
  color: #000;
  cursor: default;
}
.animation .link.active {
  animation: enter 0.4s ease-in-out;
}
.link.active > svg {
  color: rgba(var(--theme-rgb), 0.8);
}
.animation .link.active > svg {
  animation: iconEnter 0.4s ease-in-out;
}
@keyframes iconEnter {
  from,
  5% {
    color: #4a4a4a;
  }
}
@keyframes enter {
  from {
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      #0000000d 30%,
      rgba(var(--theme-rgb), 0.2) 45%,
      #00000021 60%
    );
    background-position: 0% center;
  }
  to {
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      #0000000d 30%,
      rgba(var(--theme-rgb), 0.2) 45%,
      #00000021 60%
    );
    background-position: 100% center;
  }
}
