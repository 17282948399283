.wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 2px;
  position: relative;
  width: 100%;
  max-width: var(--max-width);
}
.title {
  font-size: 32px;
  font-weight: 650;
  line-height: 34px;
  margin-bottom: 16px;
}
