.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  height: 64px;
  width: 100%;
  max-width: var(--max-width);
}
.user {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: rgba(var(--theme-rgb), 0.1);
  color: #222222;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  transform-origin: center;
  transition:
    all 0.2s ease-in-out,
    transform 0s linear;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.user:hover {
  background-color: rgba(var(--theme-rgb), 0.2);
}
.user:active {
  transform: scale(0.95);
}
.action {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 24px;
  background-color: rgba(var(--theme-rgb), 0.1);
  color: #3a3a3a;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  transform-origin: center;
  transition:
    all 0.2s ease-in-out,
    transform 0s linear;
}
.action:hover {
  background-color: rgba(var(--theme-rgb), 0.2);
}
.action:active {
  transform: scale(0.97);
}
.search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  background-color: rgba(var(--theme-rgb), 0.1);
  border-radius: 24px;
  padding: 0 14px;
  white-space: nowrap;
  min-width: 0;
  gap: 8px;
  font-size: 14px;
  line-height: 15px;
  color: #222222;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
}
.search:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--theme-rgb), 0.15);
}
.search:active {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--theme-rgb), 0.2);
}
.search > svg {
  height: 18px;
  color: #555555;
  width: 18px;
  flex-shrink: 0;
}
